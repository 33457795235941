export const MoviesIndexes: Record<'fr' | 'en', string> = {
  en: 'movies_en',
  fr: 'movies_fr'
}

export const ContactsIndexes: Record<'fr' | 'en', string> = {
  en: 'contacts_en',
  fr: 'contacts_fr'
}

export const ArticlesIndexes: Record<'fr' | 'en', string> = {
  en: 'articles_en',
  fr: 'articles_fr'
}

export const ListsIndexes: Record<'fr' | 'en', string> = {
  en: 'lists_en',
  fr: 'lists_fr'
}

export const TVSchedulesIndexes: Record<'fr' | 'en', string> = {
  en: 'tv_schedules_en',
  fr: 'tv_schedules_fr'
}
